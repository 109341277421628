import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios';

import { getStorageItem } from "./helpers";

// axios.defaults.withCredentials = true
// axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:8082/api' :'';
// axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'https://staging.backend-service.exportfeed.com/api' :'';
axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:8081/api' : 'https://etsyapp.dpl.company/api';

axios.interceptors.request.use((config) => {

  let access_token = getStorageItem("accessToken");
  if (access_token && !config.headers.common.hasOwnProperty("Authorization")) {
    config.headers.common.Authorization =
      "Bearer " + getStorageItem("accessToken");
  }
  return config;
})
window.axios = axios
Vue.config.productionTip = false
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor);

import Fragment from 'vue-frag'
Vue.directive('frag', Fragment)

import "./filters";


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
